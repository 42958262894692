import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'

export default {
  namespaced:true,
  state(){
    return{
      articles: [],
      fileName:'',
      myList: [],
      myListDeatil:{},
      total:null,
      article:null,
    }
  },
  mutations,
  actions,
  getters
}