export default {
  myInfo(state){
    return state.myInfo
  },
  token(state){
    return state.token
  },
  isAuthenticated(state){
    return !!state.token
  },
  didAutoLogout(state){
    return state.didAutoLogout
  }
}