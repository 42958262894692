<template>
  <footer id="main-footer">
    <p> Copyright &copy; 2022, All Rights Reserved</p>
  </footer>
</template>

<style scoped>
  #main-footer{
    background: #444;
    color: #fff;
    height: 10vh;
    padding: 1.875rem 0;
    text-align: center;
    font-size: 0.75rem;
  
  }
</style>